import React, {Component} from 'react';
import {Row, Col, Tooltip} from 'antd';
import {reduceAddress, registerToken, ARBM_ADDRESS} from '../../utils/utils';
import {ArrowUpOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {numeral} from '../../utils/utils';
import './Info.scss';

class Info extends Component{
  constructor(props){
   super(props);
   this.state={
     visible: false
   }
  }


  render(){
    return(
      <div className="info" align="left">
        <Row type="flex" align="middle" justify="space-between" gutter={{xs:0, sm:0, md: 32}}>
          <Col xs={24} sm={24} md={12}>
            <div className="iuoInfo">
              <h2>Launchpad Information</h2>
              <p>
                Start Time:
                <span>
                  {this.props.startTime}
                </span>
              </p>
              <p>
                End Time:
                <span>
                  {this.props.endTime}
                </span>
              </p>
              <p>
                Planned Launchpad currency:
                <span>
                  ETH
                </span>
              </p>
              <p>
                Hard Cap:
                <span>
                  {numeral(this.props.targetAmount).format('0,0.[0000000]')} ETH
                </span>
              </p>
              <p>
                Personal quota limit:
                <span>
                  Unlimited
                </span>
              </p>
              <p>
                Launchpad method:
                <Tooltip title={<div>Over-Subscription method means the more you put in, the more you will get, and you’ll get back anything that doesn’t get spent.<br/> You can subscribe as much or as little as you want to the Launchpad, and your final allocation will be based on the amount of funds they put in, as a % of all funds put in by other users. You'll get any leftover funds back when claiming your tokens after the sale.</div>}>
                  <span className="tooltip">
                    Over-Subscription <QuestionCircleOutlined/>
                  </span>
                </Tooltip>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div className="tokenInfo">
              <h2>Token Information</h2>
              <p>
                Token Full Name:
                <span>
                  ARBSWAP Token
                </span>
              </p>
              <p>
                Token Symbol:
                <span>
                  ABS
                </span>
              </p>
              <p>
                Decimals
                <span>
                  18
                </span>
              </p>
              <p>
                Total Supply
                <span>
                  1,000,000,000,000
                </span>
              </p>
              <p>
                Token Address
                <a href={`https://arbiscan.io/token/${ARBM_ADDRESS}`} target="_blank" rel="noopener noreferrer">
                  <span >
                    {reduceAddress(ARBM_ADDRESS)} <ArrowUpOutlined className="linkArrow"/>
                  </span>
                </a>
              </p>
              <p className={"addToMetaMask"} onClick={()=>{registerToken(
                ARBM_ADDRESS,
                'ABS',
                18,
                null
              )}}>
                Add to MetaMask
              </p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Info;
