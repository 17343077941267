import React, {Component} from 'react';
import {Row, Col} from 'antd';
import {numeral, checkTime} from '../../utils/utils';
import Countdown from '../../components/Countdown/Countdown';
import {LoadingOutlined} from '@ant-design/icons';
import './MyPortfolio.scss';

class MyPortfolio extends Component{
  constructor(props){
   super(props);
   this.state={
     visible: false
   }
  }


  render(){
    return(
      <div className="myPortfolio" align="middle">
        <Row type="flex" align="middle" justify="center" gutter={{xs:0, sm:0, md: 0}}>
          <Col xs={24} sm={24} md={12}>
            <div className="darkBox" align="left">
              <h2>My Launchpad Stats:</h2>
              <p>
                Participate Amount:
                <span className="floatRight">
                  {numeral(this.props.state.userParticipatedAmount).format('0,0.[00000000]')} ETH
                </span>
              </p>
              <p>
                Your Proportion:
                <span className="floatRight">
                  {numeral(this.props.state.userRatio).format('0,0.[000000]')}%
                </span>
              </p>
              <p>
                Winning Amount:
                <span className="floatRight">
                  {numeral(this.props.state.effectiveAmount).format('0,0.[00000000]')} ETH
                </span>
              </p>
              <p>
                Refund Amount:
                <span className="floatRight">
                  {numeral(this.props.state.refundAmount).format('0,0.[00000000]')} ETH
                </span>
              </p>
              <p>
                Token Purchased:
                <span className="floatRight">
                  {numeral(this.props.state.tokenPurchased).format('0,0')} ABS
                </span>
              </p>
              {
                checkTime(this.props.state.claimCountdown)
                  ?
                    null
                  : <p>
                      Claim Countdown:
                      <span className="floatRight">
                        <Countdown date={this.props.state.claimCountdown}/>
                      </span>
                    </p>
              }

              {
                checkTime(this.props.state.claimCountdown)
                  ? <div align="middle">
                    {
                      this.props.state.userClaimed
                        ?
                        <div className={"button buttonDisabled"}>
                          Claimed
                        </div>
                        :
                        <div
                          className={checkTime(this.props.state.claimCountdown) && !this.props.state.userClaimed ? "button" : "button buttonDisabled"}
                          onClick={() => {
                            this.props.claim()
                          }}>
                          {this.props.state.claimLoading ?
                            <LoadingOutlined/>
                            :
                            this.props.state.refundAmount > 0
                              ? 'Claim ABS and Refund'
                              : 'Claim ABS'
                          }
                        </div>

                    }


                  </div>
                  : null

              }
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default MyPortfolio;
