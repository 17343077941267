import React from "react";
import {Link} from "react-router-dom";
import {message} from 'antd';
import {ArbMinerAddress, reduceAddress, EthMinerAddress} from "../../utils/utils";
import twitter from "../../assets/twitter.png";
import arbiscan from "../../assets/scan.png";
import telegram from "../../assets/telegram.png";
// import ins from "../../assets/ins.png";
// import fb from "../../assets/fb.png";
// import airdrop from "../../assets/airdrop.png";

import "./Header.scss";
// import Wallets from "../Wallet/Wallets";
// import {CloseOutlined} from "@ant-design/icons";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayRBDModal: false,
    }
  }

  toggleDisplayRBDModal = () =>{
    this.setState({
      displayRBDModal: !this.state.displayRBDModal
    })
  }

  render() {
    const { address } = this.props;
    return (
      <div className={"header"}>
            <div className="walletWrapper" align="middle" onClick={() => {
                      if(!address || address === '0x00000000000000000000000000000000deadbeef'){
                        this.props.reconnect();
                      }
                      else{
                        this.props.disconnect()
                      }

                    }}>
                {!address || address === '0x00000000000000000000000000000000deadbeef' ? (
                  <span
                    className="address"

                  >
                    Connect Wallet
                  </span>
                ) : (
                  <span className="address">{reduceAddress(address)}</span>
                )}
            </div>
            <div className={"tabs"}>
              {/*<a href={"https://bnb.arbminer.org"}>*/}
              {/*  <div className={"bnbMiner" }>*/}
              {/*    BNB Miner*/}
              {/*  </div>*/}
              {/*</a>*/}

              <Link to={'/ethminer'}>
                <div className={location.hash.includes('#/ethminer') ? "active": "" } onClick={this.props.updateBg}>
                    EthMiner
                </div>
              </Link>

              <Link to={'/arbminer'}>
                <div className={location.hash.includes('#/ethminer') ? "": "active" } onClick={this.props.updateBg}>
                    ArbMiner
                </div>
              </Link>
              {/*<Link to={'/launchpad'}>*/}
              {/*  <div className={location.hash.includes('#/launchpad') ? "active": "" } onClick={this.props.updateBg}>*/}
              {/*      Launchpad*/}
              {/*  </div>*/}
              {/*</Link>*/}
              {/*<div onClick={()=>{message.info("Coming soon!")}}>*/}
              {/*      Swap*/}
              {/*</div>*/}
              {/*<div onClick={()=>{message.info("Coming soon!")}}>*/}
              {/*      Airdrop*/}
              {/*</div>*/}
            {/*  <Link to={'/beefv2'}>*/}
            {/*    <div className={""} onClick={this.props.updateBg}>*/}
            {/*        RoastBeef V2*/}
            {/*    </div>*/}
            {/*  </Link>*/}

            {/*  <Link to={'/beefv1'}>*/}
            {/*    <div onClick={this.props.updateBg}>*/}
            {/*        RoastBeef V1*/}
            {/*    </div>*/}
            {/*  </Link>*/}

              {/*<Link to={'/swap'}>*/}
              {/*  <div className={""} onClick={this.props.updateBg}>*/}
              {/*      BeefSwap*/}
              {/*  </div>*/}
              {/*</Link>*/}

              {/*<Link to={'/farm'}>*/}
              {/*  <div className={"airdrop"} onClick={this.props.updateBg}>*/}
              {/*      Farm*/}
              {/*  </div>*/}
              {/*</Link>*/}
            </div>
            {/*<Modal*/}
            {/*  visible={this.state.displayRBDModal}*/}
            {/*  onCancel={this.toggleDisplayRBDModal}*/}
            {/*  footer={null}*/}
            {/*  centered={true}*/}
            {/*  wrapClassName={'customModal'}*/}
            {/*>*/}
            {/*  <div className={"rbdModal"}>*/}
            {/*    <CloseOutlined className="close" onClick={() => {*/}
            {/*      this.toggleDisplayRBDModal();*/}
            {/*    }}/>*/}
            {/*    <h1>BeefDAO ($RBD)</h1>*/}
            {/*    <div>*/}
            {/*      BeefDAO is created to support the Roast Beef Miner Ecosystem. BeefDAO will be fully run and governed by the community and will issue its token $RBD. $RBD tokens will be airdropped to all the Beef and Burger investors and early supporters. Details about $RBD tokenomics and airdrop snapshots and claim rules will be published soon.*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Modal>*/}
            <div className={"socialMedias"}>
              <a href={`https://arbiscan.io/address/${location.hash.includes('#/ethminer') ? EthMinerAddress : ArbMinerAddress}`} target="_blank" rel="noopener noreferrer">
                <img src={arbiscan} alt={'bscscan'}/>
              </a>
              <a href={"https://twitter.com/arb_miner"} target="_blank" rel="noopener noreferrer">
                <img src={twitter} alt={'twitter'}/>
              </a>
              <a href={"https://t.me/ArbMinerorg"} target="_blank" rel="noopener noreferrer">
                <img src={telegram} alt={'telegram'}/>
              </a>
              {/*<a href={"https://instagram.com/roastbeefswap"} target="_blank" rel="noopener noreferrer">*/}
              {/*  <img src={ins} alt={'ins'}/>*/}
              {/*</a>*/}
              {/*<a href={"https://www.facebook.com/RoastBeefofficial/"} target="_blank" rel="noopener noreferrer">*/}
              {/*  <img src={fb} alt={'fb'}/>*/}
              {/*</a>*/}
            </div>
          </div>
    );
  }

}

export default Header;
