import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import {message, Modal} from 'antd';

import detectEthereumProvider from '@metamask/detect-provider';

import Routes from "./routes";
import {Utils, NETWORK_ID, checkTime, BEEF_V2_RELEASE_TIME} from './utils/utils';
import {HashRouter} from "react-router-dom";
import {addNetwork} from './utils/chainsConfig';

import './App.scss';
import Header from "./components/Header/Header";
import CountDown from "./components/Countdown/Countdown";
import {CloseOutlined} from "@ant-design/icons";

import bgImage from "./assets/bg.jpg";
import ethImage from "./assets/images/ethImage.jpg";
// import busd from "./assets/busd.jpg";
// import bgGif from "./assets/butonBg.gif";

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lang:"en",
			address: null,
      isEthMiner: true,
      showSwapModal: false//!localStorage.getItem('showV2Modal')
		}
	}

	async componentDidMount() {
    this.updateBg();
		this.readWeb3Instance();

		// if (typeof window.ethereum !== 'undefined') {
		// 	window.ethereum.on('accountsChanged', (accounts)=>{
		// 		this.readWeb3Instance();
		// 	})
		// 	window.ethereum.on('chainChanged', (accounts)=>{
		// 		console.log("chainChanged");
		// 		window.location.reload();
		// 	})
		// }
		// else if (typeof window.web3 !== 'undefined') {
		// 	console.log(window.web3);
		// }

    message.config({
      maxCount: 2,
    })
	}

  componentDidUpdate = () =>{
  }

  readWeb3Instance = async () =>{
    const provider = await detectEthereumProvider();

    if (provider) {
      // window.ethereum.enable();
      Utils.setWeb3(provider);
      provider
      .request({ method: 'eth_requestAccounts' })
      .then((accounts)=>{
        this.checkChainId(provider);
        this.handleAccountsChanged(accounts)
      })
      .catch((err) => {
        console.error(err);
      });
      provider.on('accountsChanged', (accounts)=>{this.handleAccountsChanged(accounts)})
      if(!provider.isTrust){
        provider.on('chainChanged', (accounts)=>{
          window.location.reload();
        })
      }
      
    }
    else {
      console.error('wait for MetaMask');
      Utils.setWeb3(false);
    }
  }

  handleAccountsChanged = (accounts) =>{
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.');

    } else if (accounts[0] !== this.state.address) {
      this.setState({
        address:accounts[0]
      })
    }
  }

  checkChainId = (web3) =>{
    if(parseInt(web3.chainId, 16) !== NETWORK_ID){
      console.log(1)
      addNetwork(NETWORK_ID);
    }
  }

  disconnect = () => {
    const tempAddress = this.state.address;
    this.setState({
      address: '0x00000000000000000000000000000000deadbeef',
      tempAddress: tempAddress,
    });
  };

  reconnect = () => {
    this.setState({
      address: this.state.tempAddress,
    });
  };

  toggleShowSwapModal = () =>{
    localStorage.setItem('showV2Modal', "false")
    this.setState({
      showSwapModal: !this.state.showSwapModal
    })
  }

  updateBg = () =>{
    setTimeout(()=>{
      let isEthMiner = true
    if (location.hash.includes('#/ethminer')) {
      isEthMiner = true;
    }
    else{
      isEthMiner = false;
    }
    this.setState({
      isEthMiner:isEthMiner
    })
    },10)

  }

	render() {
		return (
			<HashRouter>
  			<div className="App" style={{backgroundImage: this.state.isEthMiner ? `url(${ethImage})` : `url(${bgImage})`}}>
          <Header address={this.state.address} updateBg={this.updateBg} disconnect={this.disconnect} reconnect={this.reconnect}/>
          <div className={"container"}>
            {/*<Modal*/}
            {/*  visible={this.state.showSwapModal}*/}
            {/*  onCancel={this.toggleShowSwapModal}*/}
            {/*  footer={null}*/}
            {/*  centered={true}*/}
            {/*  wrapClassName={'customModal'}*/}
            {/*>*/}
            {/*  <div className={"showSwapModal"}>*/}
            {/*    <CloseOutlined className="close" onClick={() => {*/}
            {/*      this.toggleShowSwapModal();*/}
            {/*    }}/>*/}
            {/*    <div className={"content"}>*/}
            {/*      {*/}
            {/*        checkTime(BEEF_V2_RELEASE_TIME) ?*/}
            {/*          <>*/}
            {/*            <h1>*/}
            {/*              RoastBeef V2 is Live!!!*/}
            {/*            </h1>*/}
            {/*          </>*/}
            {/*          :*/}
            {/*          <>*/}
            {/*            <h1>*/}
            {/*              RoastBeef V2 Launch In*/}
            {/*            </h1>*/}
            {/*            <CountDown date={BEEF_V2_RELEASE_TIME}/>*/}
            {/*          </>*/}
            {/*      }*/}

            {/*      <Link to={'/beefv2'}>*/}
            {/*        <div className={"button"} onClick={this.toggleShowSwapModal}>*/}
            {/*          Go to RoastBeef V2*/}
            {/*        </div>*/}
            {/*      </Link>*/}
            {/*    </div>*/}

            {/*  </div>*/}
            {/*</Modal>*/}
            <Routes
              address={this.state.address} disconnect={this.disconnect} reconnect={this.reconnect} readWeb3Instance={this.readWeb3Instance}/>
          </div>
        </div>
			</HashRouter>
		);
	}
}

export default App;
