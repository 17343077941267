import React, {Component} from 'react';
import {Row, Col,message, Progress, Input} from 'antd';
import Info from './Info';
import MyPortfolio from './MyPortfolio';
import Countdown from '../../components/Countdown/Countdown';
import {LoadingOutlined, ArrowUpOutlined} from '@ant-design/icons';
import {
  numeral,
  Utils,
  checkTime,
  reduceAddress,
  IDO_START_TIME,
  date2CountdownString,
  IDO_END_TIME,
  ARBM_ADDRESS
} from '../../utils/utils';
import './IDO.scss';

import tokenLogo from "../../assets/images/tokenLogo.png";

class IDO extends Component{
  constructor(props){
   super(props);
   this.state={
     selectedTab:'info', // info, descriotion, myPortfolio
     raisePercent: 0,
     depositInput: '',
     targetAmount: 50,
     endTime: 'Apr 27 2023 06:00:00 UTC',
     startTime: IDO_START_TIME,
     gadPresale: 1000000000000 / 10,
     gadPerBnb: 1000000000000 / 10 / 50,
     canClaim:false,
     userClaimed:false,
     claimCountdown: 'Apr 28 2023 06:00:00 UTC'
   }
   this._mounted = false;
  }

  componentDidMount = () =>{
    this._mounted = true;
    this.timer = setInterval(()=>{this.checkContract()}, 1000);
  }

  componentDidUpdate = (prevProps) =>{
    if(this.props.address !== prevProps.address || this.props.nativeBalance !== prevProps.nativeBalance){
      this.checkContract();
    }
  }

  checkContract = async ()=>{
    if(Utils.web3 && this.props.address && Utils.launchpad){
      clearInterval(this.timer);
      this.getContractData();
    }
  }

  componentWillUnmount = () =>{
    this._mounted = false;
    clearInterval(this.timer);
    clearTimeout(this.timeoutTimer);
  }

  getContractData = async () =>{
    clearTimeout(this.timeoutTimer);
    const startTime = await Utils.launchpad.methods.startTime().call();
    const endTime = await Utils.launchpad.methods.endTime().call();
    const claimTime = await Utils.launchpad.methods.claimTime().call();
    const raisingAmount = Utils.web3.utils.fromWei(await Utils.launchpad.methods.raisingAmount().call());
    const offeringAmount = Utils.web3.utils.fromWei(await Utils.launchpad.methods.offeringAmount().call());
    const gadPerBnb = offeringAmount / raisingAmount;
    const bnbPerMillionUfo = raisingAmount / offeringAmount * 1000000;
    const billionUfoValue = bnbPerMillionUfo * this.props.bnbPrice;
    const participated = await Utils.launchpad.methods.getAddressListLength().call();
    const totalRaised = Utils.web3.utils.fromWei(await Utils.launchpad.methods.totalAmount().call());
    const totalRaisedValue = totalRaised * this.props.bnbPrice;
    const raisePercent = totalRaised/raisingAmount * 100;

    const userInfo = await Utils.launchpad.methods.userInfo(this.props.address).call();
    const userParticipatedAmount = Utils.web3.utils.fromWei(userInfo.amount);
    const userRatio = userParticipatedAmount/totalRaised * 100;
    const userClaimed = userInfo.claimed;
    const refundAmount = Utils.web3.utils.fromWei(await Utils.launchpad.methods.getRefundingAmount(this.props.address).call());
    const effectiveAmount = parseFloat(userParticipatedAmount) - parseFloat(refundAmount);
    const tokenPurchased = effectiveAmount * gadPerBnb;

    // const allowance = await Utils.arb.methods
    //   .allowance(this.props.address, Utils.launchpad._address)
    //   .call();

    if(!this._mounted){
      return;
    }
    this.setState({
      // approved: Number(allowance) > 1,
      // allowance: allowance,
      startTime: date2CountdownString(new Date(parseInt(startTime) * 1000)),
      endTime: date2CountdownString(new Date(endTime * 1000)),
      claimCountdown: date2CountdownString(new Date(parseInt(claimTime) * 1000)),
      gadPerBnb: gadPerBnb,
      targetAmount:raisingAmount,
      participated:participated,
      raisePercent: raisePercent,
      totalRaised:totalRaised,
      userParticipatedAmount:userParticipatedAmount,
      userRatio:userRatio,
      refundAmount:refundAmount,
      effectiveAmount:effectiveAmount,
      tokenPurchased:tokenPurchased,
      bnbPerMillionUfo:bnbPerMillionUfo,
      billionUfoValue:billionUfoValue,
      totalRaisedValue:totalRaisedValue,
      userClaimed:userClaimed
    })
    this.timeoutTimer = setTimeout(()=>{
      this.getContractData();
    },6000)
  }

  getButton = () =>{
    if(!checkTime(this.state.startTime)){
      return(
        <div className={"button"}>
          <Countdown date={this.state.startTime}/>
        </div>
      )
    }
    if(checkTime(this.state.endTime)){
      return (
        <div className={"button buttonDisabled"}>
          ENDED
        </div>
      )
    }

    if(this.state.depositInput > 0){
      return (
        <div className={"button"} onClick={()=>{this.deposit()}}>

          {this.state.depositLoading ?
            <LoadingOutlined />
          :
            'Confirm'
          }
        </div>
      )
    }
    return (
      <div className={"button buttonDisabled"}>
        Enter Amount
      </div>
    )
  }

  renderTabContent = () =>{
    if(this.state.selectedTab === 'myPortfolio'){
      return <MyPortfolio state={this.state} claim={()=>{this.claim()}}/>
    }

    return <Info startTime={this.state.startTime} endTime={this.state.endTime} targetAmount={this.state.targetAmount}/>
  }


  render(){
    return(
      <div className="iuo" align="middle">
        <div className="projectWrap">
          <Row type="flex" align="middle" justify="space-between" gutter={{xs:0, sm:0, md: 32}}>
            <Col xs={24} sm={24} md={12}>
              <div className="project" align="left">
                <div className="intro">
                <img src={tokenLogo} alt="tokenLogo" className="tokenLogo"/>
                  <div className="title">
                    <h1>ARBSWAP Token (<span>ABS</span>)</h1>
                    <a href={`https://arbiscan.io/token/${ARBM_ADDRESS}`} target="_blank" rel="noopener noreferrer">
                      <p>
                        {reduceAddress(ARBM_ADDRESS)} <ArrowUpOutlined className="linkArrow"/>
                      </p>
                    </a>
                  </div>
                </div>
                <div className="progress">
                  {/* <p>
                    1 ARB = {numeral(this.state.gadPerBnb).format("0,0")} ARBM
                    <span>
                      <span>{numeral(this.state.participated).format("0,0")}</span> Participated
                    </span>
                  </p> */}
                  <p>
                    1 ABS =  0.0000000005  ETH &nbsp;
                    {/*(≈ {numeral(this.state.billionUfoValue).format("$0,0.[00]")})*/}
                    {/*<span>*/}
                    {/*  <span>{numeral(this.state.participated).format("0,0")}</span> Participated*/}
                    {/*</span>*/}
                  </p>
                  <div>
                    <Progress percent={this.state.raisePercent} showInfo={false} trailColor={'white'} strokeColor={'#27A0F0'}/>
                  </div>
                  <p>
                    {numeral(this.state.raisePercent).format("0.[0000]")}%
                    <span>
                      <span>{numeral(this.state.totalRaised).format("0.[000000000]")}</span> / {numeral(this.state.targetAmount).format("0,0.[00000000]")} ETH
                    </span>
                  </p>

                  {/*<p>*/}
                  {/*  <span>*/}
                  {/*    (≈ {numeral(this.state.totalRaisedValue).format("$0,0.[00]")})*/}
                  {/*  </span>*/}
                  {/*</p>*/}
                </div>

                {/*{*/}
                {/*  checkTime(this.state.startTime) && !checkTime(this.state.endTime)*/}
                {/*    ?*/}
                {/*      <div align="middle">*/}
                {/*        <br/><br/>*/}
                {/*        IDO ENDS in:*/}
                {/*        <Countdown date={this.state.endTime}/>*/}
                {/*      </div>*/}

                {/*    :*/}
                {/*    null*/}
                {/*}*/}
              </div>
            </Col>
            <Col xs={24} sm={24} md={10}>
              <div className="participateWrap" align="left">
                <h3>Use ETH to participate</h3>
                <p>
                  <span>
                    {numeral(this.props.nativeBalance).format("0.[00000000]")} ETH
                  </span>
                  Available
                </p>

                <Input
                  suffix={
                    <div className="suffix">
                      <span>ETH </span>
                      <span className="button" onClick={()=>this.max()}>
                        MAX
                      </span>
                    </div>
                  }
                  value={this.state.depositInput}
                  placeholder={'Input ETH Amount'}
                  onChange={(e)=>this.onChangeDepositAmount(e)}
                />
                <p>
                  <span>
                    {numeral(this.state.userParticipatedAmount).format("0.[00000000]")} ETH
                  </span>
                  Invested
                </p>
                <br/>
                {this.getButton()}
              </div>
            </Col>
          </Row>

        </div>

        <div className="tabs">
          <div className={this.state.selectedTab === "info" ? "selectedTab" : null} onClick={()=>{this.changeTab('info')}}>
            Launchpad Info
          </div>
          <div className={this.state.selectedTab === "myPortfolio" ? "selectedTab" : null} onClick={()=>{this.changeTab('myPortfolio')}}>
            Claim Token
          </div>
        </div>


        {
          this.renderTabContent()
        }

      </div>
    )
  }

  changeTab = (val) =>{
    this.setState({
      selectedTab:val
    })
  }

  max = () =>{
    let value = this.props.nativeBalance - 0.0002;
    // if(Utils.web3.utils.toWei(value) > this.state.allowance){
    //   this.setState({
    //     approved: false,
    //   });
    // }
    // else{
    //   this.setState({
    //     approved: true,
    //   });
    // }
    this.setState({
      depositInput: value
    })
  }

  onChangeDepositAmount = (e) =>{
    let value = e.target.value;
    if(isNaN(value)){
      return;
    }
    // if(value !== "" && Utils.web3.utils.toWei(value) > this.state.allowance){
    //   this.setState({
    //     approved: false,
    //   });
    // }
    // else{
    //   this.setState({
    //     approved: true,
    //   });
    // }

    if(parseFloat(value) > this.props.nativeBalance){
      value = this.props.nativeBalance;
    }
    this.setState({
      depositInput: value
    })
  }

  approve = () => {
    if (
      !this.props.address ||
      this.props.address === "0x00000000000000000000000000000000deadbeef"
    ) {
      message.info(
        "Please use browser with Arbitrum One wallet installed to open the site."
      );
      return;
    }

    if (Number(this.props.nativeBalance) < 0.0001) {
      message.warning("Insufficient ETH for gas");
      return;
    }
    try{

      Utils.arb.methods.approve(Utils.arbMiner._address, '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF').send({
        from: this.props.address,
        gasPrice: 200000000
      })
      .on('transactionHash', (hash)=>{
        console.log(hash);
        message.info("Transaction sent",3);
      })
      .once('receipt', res => {
        this.fetchContractData();
        message.info("Transaction confirmed",3);
      })
      .then(res => {

      })
      .catch(err => console.log(err))
    }catch(e){

      console.log(e);
    }
  };


  deposit = async () =>{

    if(this.state.depositLoading || isNaN(parseFloat(this.state.depositInput)) || parseFloat(this.state.depositInput) <= 0){
      return;
    }
    if(parseFloat(this.props.nativeBalance) < 0.0001){
      message.info("Insufficient Gas", 3);
      return;
    }

    if(Utils.launchpad && Utils.launchpad.methods){

      let amount = Utils.web3.utils.toWei(this.state.depositInput.toString());

      Utils.launchpad.methods.deposit().send({
        from: this.props.address,
        gasPrice: 200000000,
        value: amount
      })
      .on('transactionHash', (hash)=>{
        if(!this._mounted){
          return;
        }
        this.setState({
          depositLoading: true
        })
        console.log(hash);
        message.info("transaction sent",3);
      })
      .then(res => {
        if(!this._mounted){
          return;
        }
        this.setState({
          depositLoading: false
        })
        this.getContractData();
        message.success("Transaction complete", 3)
      })
      .catch(err => console.log(err))

    }
  }

  claim = () =>{
    if(this.state.claimLoading || !checkTime(this.state.claimCountdown)){
      return;
    }
    if(parseFloat(this.props.nativeBalance) < 0.0001){
      message.info("Insufficient Gas", 3);
      return;
    }

    if(Utils.launchpad && Utils.launchpad.methods){

      Utils.launchpad.methods.harvest().send({
        from:this.props.address,
        gasPrice: 200000000
      })
      .on('transactionHash', (hash)=>{
        if(!this._mounted){
          return;
        }
        console.log(hash);
        this.setState({
          claimLoading: true
        })
        message.info("transaction sent",3);
      })
      .then(res => {
        if(!this._mounted){
          return;
        }
        this.setState({
          claimLoading: false
        })
        this.getContractData();
        message.success("Transaction complete", 3)
      })
      .catch(err => console.log(err))

    }
  }
}

export default IDO;
