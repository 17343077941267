import Web3 from "web3";
import { Interface } from '@ethersproject/abi';
import {default as MultiCall} from "./MultiCall.json";
import { default as RoastedBeef } from "./RoastedBeef.json";
import { default as BeefSwap } from "./BeefSwap.json";
import { default as BeefV2 } from "./BeefV2.json";
import { default as IERC20 } from "./IERC20.json";
import { default as Airdrop } from "./Airdrop.json";
import { default as ERC20Pool } from "./ERC20Pool.json";
import { default as PoolReferral } from "./InvitationCenter.json";
import { default as CakeFactory } from "./CakeFactory.json";
import { default as CakeRouter } from "./CakeRouter.json";
import { default as BCYC } from "./BCYC.json";
import { default as ArbMiner } from "./ArbMiner.json";
import { default as EthMiner } from "./EthMiner.json";
import { default as Launchpad } from "./Launchpad.json";
import { default as ADDRESSES } from "./addresses.json";
import {farmList} from "../views/Farm/config";
export const BSC_DEFAULT_RPC = "https://arbitrum-mainnet.infura.io";
export const UNIT = 1000000000000000000;
// export const RoastedBeefAddress = "0xd81F5DB384d604D85D158FCb8E00341Aff200E22";
export const SwapAddress = "0xbE7B7C8076dD2446eF285e9Bf645346b333aC84d";
// export const TreasuryAddress = "0x2e3e7Dd8Ff12Cf80A455cc022e2534D19F1410F5";
// export const multiCallAddress = "0xE1dDc30f691CA671518090931e3bFC1184BFa4Aa";
// export const RBDAddress = "0x8C3de6f30e1942B495fC6BAc577Aa53d244f01b6";
export const AirdropAddress = "0x08da5E0db351D709E5C7CfB91817D66e2d2EaD26";
// export const BeefV2Address = "0xb26B8AeDa5Df31AB464ae6e6CF7938A4cC31fB3C"//
export const BcycAddress = "0x782E64e6c0Ab1cb600c9Be730d1Cf4Fb8e6a2717";
export const BusdAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
export const ArbMinerAddress = "0x0Df3c77a25d86c3c5F26d1931e81Db0f7d991414";
export const ArbAddress = "0x912CE59144191C1204E64559FE8253a0e49E6548";
export const ARBM_ADDRESS = "0x1c4DbfeEc4E9A066D937b967E74E427eAdD683c3";
export const LaunchpadAddress = "0xED52df402DC052Bbfb7E8777be4414cfD14bf118";
export const EthMinerAddress = "0xb242e3933AdA226968BdD9DA6a0734d0F5C84646"

export const NETWORK_ID = 42161;
export const Utils = {
    web3: false,
    // roastedBeef: false,
    swap: false,
    // beefv2: false,
    addresses: ADDRESSES,
    owner:"0x0340d5cb7b3a69fE379b082E0f6978540f07898A",
    swapOwner: "0x0340d5cb7b3a69fE379b082E0f6978540f07898A",
    liquidityPools: farmList,
    launchpad: null,
    async setWeb3(provider) {
        this.web3 = new Web3(provider || BSC_DEFAULT_RPC);

        this.arb = new this.web3.eth.Contract(IERC20.abi, ArbAddress);
        this.arbMiner = new this.web3.eth.Contract(ArbMiner.abi, ArbMinerAddress);
        this.ethMiner = new this.web3.eth.Contract(EthMiner.abi, EthMinerAddress);
        this.launchpad = new this.web3.eth.Contract(Launchpad.abi, LaunchpadAddress);

    }
};

export const SECOND = 1;
export const MINUTES = SECOND * 60;
export const HOUR = MINUTES * 60;
export const DAY = HOUR * 24;
export const YEAR = DAY * 365;

export const RELEASE_TIME = "Sat Apr 16 2022 14:00:00 UTC";
export const SWAP_RELEASE_TIME = "Mon Apr 25 2022 14:00:00 UTC"
export const ROAST_TIME = "Thu Dec 6 2022 14:00:00 UTC"
export const MATIC_TIME = "Sun May 1 2022 14:00:00 UTC"
export const BEEFCHAIN_RELEASE_TIME = "Sat May 7 2022 14:00:00 UTC"
export const AIRDROP_TIME = "Thu May 25 2022 14:00:00 UTC"
export const FARM_RELEASE_DATE = 'Tue May 25 2022 14:30:00 UTC'
export const DAO2_RELEASE_TIME = 'Thu Jun 2 2022 14:00:00 UTC'
// export const BEEF_V2_RELEASE_TIME = 'Sun Jun 12 2022 14:00:00 UTC';
// export const BEEF_V2_CONTRACT_RELEASE_TIME = 'Sun Jun 12 2022 13:50:00 UTC';
export const BULL_RELEASE_TIME = 'Wed Jun 23 2022 14:00:00 UTC';
export const ARB_MINER_RELEASE_TIME = 'Sat Apr 8 2023 15:00:00 UTC';
export const ETH_MINER_RELEASE_TIME = 'Wed Nov 08 2023 07:00:00 UTC';
export const numeral = require("numeral");
const whitelist = "0xa51CEc74A21307f86F9D108C035B7E4f3D08652C";

export const IDO_START_TIME = 'Apr 20 2023 06:00:00 UTC';
export const IDO_END_TIME = 'Apr 27 2023 06:00:00 UTC';


export const checkTime = (time=RELEASE_TIME) => {
  // return true;
  if(Utils.web3 && Utils.web3.currentProvider.selectedAddress && whitelist.toLowerCase() === Utils.web3.currentProvider.selectedAddress.toLowerCase()){
    return true;
  }
  let now = (new Date()).getTime()/1000;
    let deadline = (new Date(time)).getTime()/1000;
    if(now >= deadline){
        return true;
    }
    return false;
}

export const reduceAddress = (addr) =>{
    if(addr){
        return addr.substring(0,4) + "..." + addr.substring(addr.length - 4,addr.length);
    }
}

export const isContract = async (addr) =>{
  if(!Utils.web3 || !Utils.web3.utils.isAddress(addr)){
    return false;
  }
  const code = await Utils.web3.eth.getCode(addr);
  return code !== "0x";
}

export const date2CountdownString = (date) => {
    let n = date.toUTCString();
    let timeString = n.substring(0,3)+n.substring(7,12)+n.substring(5,7)+n.substring(11,n.length-4)+" UTC";
    return timeString;
}

export const getGasPrice = async () =>{
    if(!Utils.web3){
        return 1000000000;
    }
    return Math.floor(parseInt(await Utils.web3.eth.getGasPrice()) * 1.5);
}


export const validateAddr = async (addr) =>{
  if(!Utils.web3.utils.isAddress(addr)){
    return false;
  }

  return addr !== "0x0000000000000000000000000000000000000000" &&
  !(addr.toLowerCase()).includes(Utils.web3.currentProvider.selectedAddress.toLowerCase())
}

const PAGE = 100;
export const multiCall = async (abi, calls) => {
  const multi = Utils.multiCall;
  const itf = new Interface(abi)
  let res = []

  if (calls.length > PAGE) {
    let i = 0
    while (i < calls.length / PAGE) {
      const newCalls = calls.slice(i * PAGE, PAGE * (i + 1))
      const calldata = newCalls.map((call) => [call[0].toLowerCase(), itf.encodeFunctionData(call[1], call[2])])
      const { returnData } = await multi.methods.aggregate(calldata).call()
      i++
      res = res.concat(returnData.map((call, index) => itf.decodeFunctionResult(newCalls[index][1], call)))
    }
  } else {
    const calldata = calls.map((call) => [call[0].toLowerCase(), itf.encodeFunctionData(call[1], call[2])])
    const { returnData } = await multi.methods.aggregate(calldata).call()
    res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i][1], call))
  }
  return res
}

export const registerToken = async (
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImage,
) => {
  await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  })
}