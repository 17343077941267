export const farmList = {
  "rbd1bnb2rbd":{
    name: "RBD-ETH LP",
    token1: "RBD",
    token2: 'ETH',
    output: "RBD",
    multiplier: 10,
    isLp: true
  },

  "usdt2rbd":{
    name:"USDT",
    token1: "USDT",
    token2: null,
    output: "RBD",
    multiplier: 3
  },
  "busd2rbd":{
    name:"BUSD",
    token1: "BUSD",
    token2: null,
    output: "RBD",
    multiplier: 3
  },
  "wbnb2rbd":{
    name:"WETH",
    token1: "WETH",
    token2: null,
    output: "RBD",
    multiplier: 3
  },
}