import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";
import IDO from "./views/IDO/IDO";
// import Swap from "./views/Swap/Swap";
// import Airdrop from "./views/Airdrop/Airdrop";
// import Farm from "./views/Farm/Farm";
// import BoredBeef from "./views/BoredBeef";
import ArbMiner from "./views/ArbMiner/ArbMiner";
import EthMiner from "./views/EthMiner/EthMiner";
import {
  Utils,
  date2CountdownString,
  // TreasuryAddress,
  SWAP_RELEASE_TIME,
  checkTime,
  // multiCall,
  // BEEF_V2_RELEASE_TIME,
} from './utils/utils';
// import { default as BeefSwap } from "./utils/BeefSwap.json";
// import RoastBeef2 from "./views/RoastBeef2/RoastBeef2";
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nativeBalance: 0,
      contractBalance: 0,
      barrelFullTime: 'Aug 09 2000 14:00:00 UTC',
      // languageFile: require('./assets/languages/en.json'),
      swapState:{
        tokenPrice:0,
        totalPlayer: 0,
        marketcap: 0,
        totalSupply: 0,
        tokenValue:0,
        marketcapValue:0,
        treasury: 0,
      }
    }
  }
  async componentDidMount() {
    this.checkContract()
    this.timer = setInterval(()=>{this.checkContract()}, 1000);
  }

  componentDidUpdate = (prevProps) =>{
    if(prevProps.address !== this.props.address){
      this.checkContract()
    }
  }

  checkContract=()=>{
    if(Utils.web3 &&
      this.props.address){
      clearInterval(this.timer);
      this.getContractStats();
      // this.getSwapData();
    }
  }

  getContractStats= async ()=>{
    clearTimeout(this.timeout)
    // if (!Utils.roastedBeef){
    //   return;
    // }
    //  if(Utils.roastedBeef) {
    //    try {
    //      const nativeBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(this.props.address))
    //      const contractBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(Utils.roastedBeef._address))
    //      const eggsPerMiner = await Utils.roastedBeef.methods.EGGS_TO_HATCH_1MINERS().call();
    //      const myMiners = await Utils.roastedBeef.methods.hatcheryMiners(this.props.address).call();
    //      const myEggs = await Utils.roastedBeef.methods.getMyEggs().call({from: this.props.address});
    //      let myEarns = 0;
    //      let hireEstimates = 0;
    //      let bnbPerHour = 0;
    //      let barrelFullTime = 'Aug 09 2000 14:00:00 UTC';
    //      if (myEggs > 0) {
    //        myEarns = Utils.web3.utils.fromWei(await Utils.roastedBeef.methods.calculateEggSell(myEggs).call());
    //        const estimateEggs = await Utils.roastedBeef.methods.calculateEggBuySimple(Utils.web3.utils.toWei('0.1')).call();
    //        hireEstimates = estimateEggs / eggsPerMiner;
    //        const lastHatchTime = await Utils.roastedBeef.methods.lastHatch(this.props.address).call();
    //
    //        if (contractBalance * 1 > 0) {
    //          const curTime = new Date().getTime();
    //          bnbPerHour = myEarns / (curTime / 1000 - lastHatchTime) * 3600;
    //        }
    //        if (Number(bnbPerHour) < 0.000001) {
    //          bnbPerHour = 0;
    //        }
    //        if (Number(myEarns) < 0.000001) {
    //          myEarns = 0;
    //        }
    //
    //        barrelFullTime = date2CountdownString(new Date((parseInt(lastHatchTime) + 86400) * 1000));
    //
    //      }
    //
    //
    //      this.setState({
    //        contractBalance: contractBalance,
    //        nativeBalance: nativeBalance,
    //        myMiners: myMiners,
    //        myEarns: myEarns,
    //        hireEstimates: hireEstimates,
    //        bnbPerHour: bnbPerHour,
    //        barrelFullTime: barrelFullTime,
    //      })
    //    } catch (e) {
    //      console.log(e);
    //    }
    //  }
    //
    // if(Utils.beefv2){
    //   try{
    //     const contractBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(Utils.beefv2._address))
    //     const eggsPerMiner = await Utils.beefv2.methods.EGGS_TO_HATCH_1MINERS().call();
    //     const myMiners = await Utils.beefv2.methods.hatcheryMiners(this.props.address).call();
    //     const myEggs = await Utils.beefv2.methods.getMyEggs().call({from:this.props.address});
    //     let myEarns = 0;
    //     let hireEstimates = 0;
    //     let bnbPerHour = 0;
    //     let barrelFullTime = 'Aug 09 2000 14:00:00 UTC';
    //     if(myEggs > 0){
    //       myEarns = Utils.web3.utils.fromWei(await Utils.beefv2.methods.calculateEggSell(myEggs).call());
    //       const estimateEggs = await Utils.beefv2.methods.calculateEggBuySimple(Utils.web3.utils.toWei('0.1')).call();
    //       hireEstimates = estimateEggs/eggsPerMiner;
    //       const lastHatchTime = await Utils.beefv2.methods.lastHatch(this.props.address).call();
    //
    //       if(contractBalance*1 > 0){
    //         const curTime = new Date().getTime();
    //         bnbPerHour = myEarns/(curTime/ 1000 - lastHatchTime) * 3600;
    //       }
    //       if(Number(bnbPerHour) < 0.000001){
    //         bnbPerHour = 0;
    //       }
    //       if(Number(myEarns) < 0.000001){
    //         myEarns = 0;
    //       }
    //
    //       barrelFullTime = date2CountdownString(new Date((parseInt(lastHatchTime) + 86400) * 1000));
    //
    //     }
    //
    //     this.setState({
    //       contractBalanceV2:contractBalance,
    //       myMinersV2: myMiners,
    //       myEarnsV2: myEarns,
    //       hireEstimatesV2: hireEstimates,
    //       bnbPerHourV2: bnbPerHour,
    //       barrelFullTimeV2:barrelFullTime
    //     })
    //   }catch(e){
    //     console.log(e);
    //   }
    // }

    if(Utils.arbMiner && Utils.arb){
      try{
        const nativeBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(this.props.address))
        const contractBalance = Utils.web3.utils.fromWei(await Utils.arb.methods.balanceOf(Utils.arbMiner._address).call())
        const eggsPerMiner = await Utils.arbMiner.methods.EGGS_TO_HATCH_1MINERS().call();
        const myMiners = await Utils.arbMiner.methods.hatcheryMiners(this.props.address).call();
        const myEggs = await Utils.arbMiner.methods.getMyEggs().call({from:this.props.address});
        const arbBalance = await Utils.arb.methods.balanceOf(this.props.address).call();
        let myEarns = 0;
        let hireEstimates = 0;
        let bnbPerHour = 0;
        let barrelFullTime = 'Aug 09 2000 14:00:00 UTC';
        if(myEggs > 0){
          myEarns = Utils.web3.utils.fromWei(await Utils.arbMiner.methods.calculateEggSell(myEggs).call());
          const estimateEggs = await Utils.arbMiner.methods.calculateEggBuySimple(Utils.web3.utils.toWei('0.1')).call();
          hireEstimates = estimateEggs/eggsPerMiner;
          const lastHatchTime = await Utils.arbMiner.methods.lastHatch(this.props.address).call();

          if(contractBalance*1 > 0){
            const curTime = new Date().getTime();
            bnbPerHour = myEarns/(curTime/ 1000 - lastHatchTime) * 3600;
          }

          if(Number(bnbPerHour) < 0.000001){
            bnbPerHour = 0;
          }
          if(Number(myEarns) < 0.000001){
            myEarns = 0;
          }

          barrelFullTime = date2CountdownString(new Date((parseInt(lastHatchTime) + 86400) * 1000));

        }

        this.setState({
          nativeBalance:nativeBalance,
          contractBalanceArb:contractBalance,
          myMinersArb: myMiners,
          myEarnsArb: myEarns,
          hireEstimatesArb: hireEstimates,
          bnbPerHourArb: bnbPerHour,
          barrelFullTimeArb:barrelFullTime,
          arbBalance: Utils.web3.utils.fromWei(arbBalance),
        })
      }catch(e){
        console.log(e);
      }
    }

    if(Utils.ethMiner){
      try{
        const nativeBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(this.props.address))
        const contractBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(Utils.ethMiner._address))
        const eggsPerMiner = await Utils.ethMiner.methods.EGGS_TO_HATCH_1MINERS().call();
        const myMiners = await Utils.ethMiner.methods.hatcheryMiners(this.props.address).call();
        const myEggs = await Utils.ethMiner.methods.getMyEggs().call({from:this.props.address});
        let myEarns = 0;
        let hireEstimates = 0;
        let bnbPerHour = 0;
        let barrelFullTime = 'Aug 09 2000 14:00:00 UTC';
        if(myEggs > 0){
          myEarns = Utils.web3.utils.fromWei(await Utils.ethMiner.methods.calculateEggSell(myEggs).call());
          const estimateEggs = await Utils.ethMiner.methods.calculateEggBuySimple(Utils.web3.utils.toWei('0.1')).call();
          hireEstimates = estimateEggs/eggsPerMiner;
          const lastHatchTime = await Utils.ethMiner.methods.lastHatch(this.props.address).call();

          if(contractBalance*1 > 0){
            const curTime = new Date().getTime();
            bnbPerHour = myEarns/(curTime/ 1000 - lastHatchTime) * 3600;
          }

          if(Number(bnbPerHour) < 0.000001){
            bnbPerHour = 0;
          }
          if(Number(myEarns) < 0.000001){
            myEarns = 0;
          }

          barrelFullTime = date2CountdownString(new Date((parseInt(lastHatchTime) + 86400) * 1000));

        }

        this.setState({
          nativeBalance:nativeBalance,
          contractBalanceEth:contractBalance,
          myMinersEth: myMiners,
          myEarnsEth: myEarns,
          hireEstimatesEth: hireEstimates,
          bnbPerHourEth: bnbPerHour,
          barrelFullTimeEth:barrelFullTime,
          ethBalance: nativeBalance,
        })
      }catch(e){
        console.log(e);
      }
    }

    this.timeout = setTimeout(()=>{
      this.getContractStats();
    }, 3000)
  }
  
  // getSwapData = async () =>{
  //   if (!Utils.swap){
  //     return;
  //   }
  //   clearTimeout(this.swapTimeout);
  //
  //   const bnbBalance = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(this.props.address));
  //   const treasury = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(TreasuryAddress));
  //   const bnbPrice = 0;
  //   this.setState({
  //     bnbPrice: 0,
  //     bnbBalance:bnbBalance,
  //   })
  //   //get comet data
  //   if(Utils.swap && checkTime(SWAP_RELEASE_TIME)){
  //     const buyPrice = Utils.web3.utils.fromWei(await Utils.swap.methods.buyPrice().call());
  //     const totalPlayer = await Utils.swap.methods.totalPlayer().call();
  //     const totalSupply = Utils.web3.utils.fromWei(await Utils.swap.methods.totalSupply().call());
  //     const marketcap = Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(Utils.swap._address));
  //     const marketcapValue = parseFloat(marketcap) * bnbPrice;
  //     const tokenValue = parseFloat(buyPrice) * bnbPrice;
  //
  //     this.setState({
  //       swapState:{
  //         tokenPrice:buyPrice,
  //         totalPlayer: totalPlayer,
  //         marketcap: marketcap,
  //         totalSupply: totalSupply,
  //         tokenValue:tokenValue.toFixed(10),
  //         marketcapValue:marketcapValue,
  //         treasury:treasury,
  //       }
  //
  //     })
  //   }
  //   else{
  //     this.setState({
  //       swapState: {
  //         tokenPrice: 0,
  //         totalPlayer: 0,
  //         marketcap: 0,
  //         totalSupply: 0,
  //         tokenValue: 0,
  //         marketcapValue: 0,
  //         treasury:treasury,
  //       }
  //     })
  //   }
  //
  //
  //   this.swapTimeout = setTimeout(()=>{
  //     this.getSwapData();
  //   },2000)
  // }

  render(){
    return(

        <Switch>
          <Route exact path="/" render={
            ()=>(
              <EthMiner
                address={this.props.address}
                ethBalance={this.state.ethBalance}
                nativeBalance={this.state.nativeBalance}
                myMiners={this.state.myMinersEth}
                myEarns={this.state.myEarnsEth}
                hireEstimates={this.state.hireEstimatesEth}
                bnbPerHour={this.state.bnbPerHourEth}
                contractBalance={this.state.contractBalanceEth}
                barrelFullTime={this.state.barrelFullTimeEth}
                disconnect={this.props.disconnect}
                reconnect={this.props.reconnect}
                readWeb3Instance={this.props.readWeb3Instance}
              />
            )
          }/>

          <Route exact path="/ethminer" render={
            ()=>(
              <EthMiner
                address={this.props.address}
                ethBalance={this.state.ethBalance}
                nativeBalance={this.state.nativeBalance}
                myMiners={this.state.myMinersEth}
                myEarns={this.state.myEarnsEth}
                hireEstimates={this.state.hireEstimatesEth}
                bnbPerHour={this.state.bnbPerHourEth}
                contractBalance={this.state.contractBalanceEth}
                barrelFullTime={this.state.barrelFullTimeEth}
                disconnect={this.props.disconnect}
                reconnect={this.props.reconnect}
                readWeb3Instance={this.props.readWeb3Instance}
              />
            )
          }/>

          <Route exact path="/arbminer" render={
            ()=>(
              <ArbMiner
                address={this.props.address}
                arbBalance={this.state.arbBalance}
                nativeBalance={this.state.nativeBalance}
                myMiners={this.state.myMinersArb}
                myEarns={this.state.myEarnsArb}
                hireEstimates={this.state.hireEstimatesArb}
                bnbPerHour={this.state.bnbPerHourArb}
                contractBalance={this.state.contractBalanceArb}
                barrelFullTime={this.state.barrelFullTimeArb}
                disconnect={this.props.disconnect}
                reconnect={this.props.reconnect}
                readWeb3Instance={this.props.readWeb3Instance}
              />
            )
          }/>
          
          {/*<Route exact path="/launchpad" render={()=>(*/}
          {/*  <IDO*/}
          {/*    bnbPrice = {0}*/}
          {/*    nativeBalance={this.state.nativeBalance}*/}
          {/*    lang={this.props.lang}*/}
          {/*    address={this.props.address}*/}
          {/*    arbBalance={this.state.arbBalance}*/}
          {/*  />*/}
          {/*)}/>*/}

          {/*<Route exact path="/nft" render={*/}
          {/*  ()=>(*/}
          {/*    <BoredBeef*/}
          {/*      address={this.props.address}*/}
          {/*      nativeBalance={this.state.nativeBalance}*/}
          {/*    />*/}
          {/*  )*/}
          {/*}/>*/}

          {/*<Route exact path="/" render={*/}
          {/*  ()=>(*/}
          {/*    <Home*/}
          {/*      address={this.props.address}*/}
          {/*      nativeBalance={this.state.nativeBalance}*/}
          {/*      myMiners={this.state.myMiners}*/}
          {/*      myEarns={this.state.myEarns}*/}
          {/*      hireEstimates={this.state.hireEstimates}*/}
          {/*      lastHatchTime={this.state.lastHatchTime}*/}
          {/*      bnbPerHour={this.state.bnbPerHour}*/}
          {/*      contractBalance={this.state.contractBalance}*/}
          {/*      barrelFullTime={this.state.barrelFullTime}*/}
          {/*      disconnect={this.props.disconnect}*/}
          {/*      reconnect={this.props.reconnect}*/}
          {/*      readWeb3Instance={this.props.readWeb3Instance}*/}
          {/*    />*/}
          {/*  )*/}
          {/*}/>*/}

          {/*<Route exact path="/swap" render={*/}
          {/*  ()=>(*/}
          {/*    <Swap*/}
          {/*      address={this.props.address}*/}
          {/*      nativeBalance={this.state.nativeBalance}*/}
          {/*      languageFile={this.state.languageFile}*/}
          {/*      disconnect={this.props.disconnect}*/}
          {/*      reconnect={this.props.reconnect}*/}
          {/*      state={this.state.swapState}*/}
          {/*      readWeb3Instance={this.props.readWeb3Instance}*/}
          {/*    />*/}
          {/*  )*/}
          {/*}/>*/}
          {/*<Route exact path="/airdrop" render={*/}
          {/*  ()=>(*/}
          {/*    <Airdrop*/}
          {/*      address={this.props.address}*/}
          {/*      nativeBalance={this.state.nativeBalance}*/}
          {/*      languageFile={this.state.languageFile}*/}
          {/*      disconnect={this.props.disconnect}*/}
          {/*      reconnect={this.props.reconnect}*/}
          {/*      state={this.state.beefv2}*/}
          {/*      readWeb3Instance={this.props.readWeb3Instance}*/}
          {/*    />*/}
          {/*  )*/}
          {/*}/>*/}

          {/*<Route exact path="/farm" render={*/}
          {/*  ()=>(*/}
          {/*    <Farm*/}
          {/*      address={this.props.address}*/}
          {/*      nativeBalance={this.state.nativeBalance}*/}
          {/*      languageFile={this.state.languageFile}*/}
          {/*      disconnect={this.props.disconnect}*/}
          {/*      reconnect={this.props.reconnect}*/}
          {/*      state={this.state.swapState}*/}
          {/*      readWeb3Instance={this.props.readWeb3Instance}*/}
          {/*    />*/}
          {/*  )*/}
          {/*}/>*/}

          {/*<Route exact path="/beefv2" render={*/}
          {/*  ()=>(*/}
          {/*    <RoastBeef2*/}
          {/*      address={this.props.address}*/}
          {/*      nativeBalance={this.state.nativeBalance}*/}
          {/*      myMiners={this.state.myMinersV2}*/}
          {/*      myEarns={this.state.myEarnsV2}*/}
          {/*      hireEstimates={this.state.hireEstimatesV2}*/}
          {/*      lastHatchTime={this.state.lastHatchTimeV2}*/}
          {/*      bnbPerHour={this.state.bnbPerHourV2}*/}
          {/*      contractBalance={this.state.contractBalanceV2}*/}
          {/*      barrelFullTime={this.state.barrelFullTimeV2}*/}
          {/*      disconnect={this.props.disconnect}*/}
          {/*      reconnect={this.props.reconnect}*/}
          {/*      readWeb3Instance={this.props.readWeb3Instance}*/}
          {/*    />*/}
          {/*  )*/}
          {/*}/>*/}

          <Route render={
            ()=>(
              <EthMiner
                address={this.props.address}
                ethBalance={this.state.ethBalance}
                nativeBalance={this.state.nativeBalance}
                myMiners={this.state.myMinersEth}
                myEarns={this.state.myEarnsEth}
                hireEstimates={this.state.hireEstimatesEth}
                bnbPerHour={this.state.bnbPerHourEth}
                contractBalance={this.state.contractBalanceEth}
                barrelFullTime={this.state.barrelFullTimeEth}
                disconnect={this.props.disconnect}
                reconnect={this.props.reconnect}
                readWeb3Instance={this.props.readWeb3Instance}
              />
            )
          }/>
        </Switch>

    )
  }
}

export default Routes;
