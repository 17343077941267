import React from "react";
import { default as numeral } from "numeral";
import { Input, message, Modal } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CountUp from "react-countup";
import Wallets from "../../components/Wallet/Wallets";
import {
  Utils,
  checkTime,
  ARB_MINER_RELEASE_TIME,
  ArbMinerAddress,
} from "../../utils/utils";
import logo from "../../assets/logo.png";
import grill from "../../assets/triller.png";
import slogan1 from "../../assets/slogan/slogan1.png";
import slogan2 from "../../assets/slogan/slogan2.png";
import slogan3 from "../../assets/slogan/slogan3.png";
import slogan4 from "../../assets/slogan/slogan4.png";
import WP from "../../assets/wp.pdf";

import "./ArbMiner.scss";
import Countdown from "../../components/Countdown/Countdown";

class ArbMiner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "xxx",
      input: 0,
      blinkMyMiners: false,
      blinkMyEarns: false,
      myEarnsStart: 0,
      myEarns: this.props.myEarns,
    };

    this.render = this.render.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.address !== prevProps.address && Utils.web3) {
      this.checkContract();
    }
    if (this.props.myMiners !== prevProps.myMiners) {
      this.setState({ blinkMyMiners: true }, () => {
        setTimeout(() => {
          this.setState({
            blinkMyMiners: false,
          });
        }, 2000);
      });
    }

    if (this.props.myEarns !== prevProps.myEarns) {
      this.setState(
        {
          myEarns: this.props.myEarns,
          blinkMyEarns: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              myEarnsStart: this.props.myEarns,
              blinkMyEarns: false,
            });
          }, 3000);
        }
      );
    }
  };

  ComponentDidMount = () => {
    this.timer = setInterval(() => {
      this.checkContract();
    }, 1000);
  };

  checkContract = () => {
    if (Utils.web3 && this.props.address && Utils.arb && Utils.arbMiner) {
      clearInterval(this.timer);
      this.fetchContractData();
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  fetchContractData = async () => {
    const allowance = await Utils.arb.methods
      .allowance(this.props.address, Utils.arbMiner._address)
      .call();
    this.setState({
      approved: Number(allowance) > 1,
      allowance: allowance,
    });
  };

  changeInput = (val) => {
    if (isNaN(val)) {
      return;
    }
    if(val !== "" && Utils.web3.utils.toWei(val) > this.state.allowance){
      this.setState({
        approved: false,
      });
    }
    else{
      this.setState({
        approved: true,
      });
    }
    this.setState({
      input: val,
    });
  };

  toggleDisplayWalletModal = () => {
    this.setState({
      displayWalletModal: !this.state.displayWalletModal,
    });
  };

  render() {
    const { address, myMiners, contractBalance, arbBalance } = this.props;
    const refLink = `${window.location.origin}/#/?ref=${address}`;
    return (
      <div className={"arbMiner"}>
        <img src={logo} alt={"logo"} className={"logo"} />

        <Modal
          visible={this.state.displayWalletModal}
          onCancel={this.toggleDisplayWalletModal}
          footer={null}
          centered={true}
          wrapClassName={"customModal"}
        >
          <Wallets
            toggleDisplayWalletModal={this.toggleDisplayWalletModal}
            address={address}
            reconnect={this.props.reconnect}
            readWeb3Instance={this.props.readWeb3Instance}
          />
        </Modal>

        {checkTime(ARB_MINER_RELEASE_TIME) ? null : (
          <div className={"countdownWrapper"}>
            <div>
              <Countdown date={ARB_MINER_RELEASE_TIME} />
            </div>
            <div>{ARB_MINER_RELEASE_TIME}</div>
          </div>
        )}

        <a href={WP} target="_blank"
            rel="noopener noreferrer"
            >
          <div className={"whitepaper"}>
            WHITEPAPER
          </div>
        </a>


        <div className={"sloganWrapper"}>
          <div className={"sloganItem"}>
            <img src={slogan1} alt={"slogan1"} />
            First Miner on Arbitrum
          </div>
          <div className={"sloganItem"}>
            <img src={slogan2} alt={"slogan2"} />
            Earn 8% ARB Daily
          </div>
          <div className={"sloganItem"}>
            <img src={slogan3} alt={"slogan3"} />
            10% Instant Referral Rewards
          </div>
          <a
            href={`https://arbiscan.io/address/${ArbMinerAddress}#code`}
            target="_blank"
            rel="noopener noreferrer"
            className={"sloganItem"}
          >
            <img src={slogan4} alt={"slogan4"} />
            Verified and Audited Contract
          </a>
        </div>
        <div className={"mainContent"}>
          <div className={"box leftBox"}>
            <div className={"dataRow"}>
              <div className={"name"}>Contract</div>
              <div className={"value"}>
                {numeral(contractBalance).format("0,0.[0000]")} ARB
              </div>
            </div>
            <div className={"dataRow"}>
              <div className={"name"}>Wallet</div>
              <div className={"value"}>
                {numeral(arbBalance).format("0,0.[0000]")} ARB
              </div>
            </div>
            <div className={"dataRow"}>
              <div className={"name"}>Your Miners</div>
              <div
                className={
                  this.state.blinkMyMiners ? "value blink_me" : "value"
                }
              >
                {numeral(myMiners).format("0,0.[0000]")} Miners
              </div>
            </div>

            <Input
              value={`${this.state.input}`}
              onChange={(e) => {
                this.changeInput(e.target.value);
              }}
              className={"antInput"}
              suffix={<span className={"suffix"}>ARB</span>}
            />
            {this.state.approved ? (
              <div
                className={"buyButton"}
                onClick={() => {
                  this.buy();
                }}
              >
                Buy Miners
              </div>
            ) : (
              <div
                className={"buyButton"}
                onClick={() => {
                  this.approve();
                }}
              >
                APPROVE
              </div>
            )}

            <div className={"actionWrapper"}>
              <div className={"dataRow"}>
                <div className={"name"}>Mining Rewards</div>
                <div
                  className={
                    this.state.blinkMyEarns ? "value blink_me" : "value"
                  }
                >
                  <CountUp
                    start={this.state.myEarnsStart}
                    end={this.state.myEarns}
                    duration={2}
                    separator=","
                    decimals={6}
                    decimal="."
                    suffix="ARB"
                  />
                  {/*{numeral(myEarns).format('0,0.[000000]')} ETH*/}
                </div>
              </div>
              <div className={"actionButtons"}>
                {/*<Tooltip title={"Compound your earning beef"}>*/}
                <div
                  onClick={() => {
                    this.compound();
                  }}
                >
                  COMPOUND
                </div>
                {/*</Tooltip>*/}
                <div
                  onClick={() => {
                    this.withdraw();
                  }}
                >
                  HARVEST
                </div>
              </div>
            </div>
          </div>

          <div className={"box rightBox"}>
            <div className={"contractInfo"}>
              <img src={grill} alt={"grill"} />
              <div className={"data"}>
                <h1>Mining Facts</h1>
                <div className={"dataRow"}>
                  <div className={"name"}>Daily Return</div>
                  <div className={"value"}>8%</div>
                </div>
                <div className={"dataRow"}>
                  <div className={"name"}>APR</div>
                  <div className={"value"}>2920%</div>
                </div>
                <div className={"dataRow"}>
                  <div className={"name"}>Referral</div>
                  <div className={"value"}>10%</div>
                </div>
              </div>
            </div>
            <div className={"referral"}>
              <h1>Referral Link</h1>
              <p>
                Earn 10% of the ARB used to buy miner from anyone who uses your
                referral link
              </p>
              <div className={"refWrapper"}>
                <div className={"referralLink"}>
                  {address ? refLink : "Connect Wallet"}
                </div>
                <CopyToClipboard
                  text={refLink}
                  onCopy={() => {
                    message.info("Copied to clipboard");
                  }}
                >
                  <div className={"copyButton"}>COPY</div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  approve = () => {
    if (
      !this.props.address ||
      this.props.address === "0x00000000000000000000000000000000deadbeef"
    ) {
      message.info(
        "Please use browser with Arbitrum One wallet installed to open the site."
      );
      return;
    }


    if (Number(this.props.nativeBalance) < 0.0002) {
      message.warning("Insufficient ETH for gas");
      return;
    }
    try{

      Utils.arb.methods.approve(Utils.arbMiner._address, '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF').send({
        from: this.props.address,
        gasPrice: 200000000
      })
      .on('transactionHash', (hash)=>{
        console.log(hash);
        message.info("Transaction sent",3);
      })
      .once('receipt', res => {
        this.fetchContractData();
        message.info("Transaction confirmed",3);
      })
      .then(res => {

      })
      .catch(err => console.log(err))
    }catch(e){

      console.log(e);
    }
  };

  buy = () => {
    if (!checkTime(ARB_MINER_RELEASE_TIME)) {
      message.info("Coming Soon");
      return;
    }
    if (
      !this.props.address ||
      this.props.address === "0x00000000000000000000000000000000deadbeef"
    ) {
      message.info(
        "Please use browser with Arbitrum One wallet installed to open the site."
      );
      return;
    }
    if (Number(this.props.nativeBalance) < 0.0002) {
      message.warning("Insufficient ETH for gas < 0.0002");
      return;
    }

    if (Number(this.props.arbBalance) < Number(this.state.input)) {
      message.warning("Insufficient ARB");
      return;
    }
    if (this.state.input * 1 < 1) {
      message.warning("Minimum buy amount 1 ARB");
      return;
    }
    try {
      const callValue = Utils.web3.utils.toWei(this.state.input);
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      let affrAddr = urlParams.get("ref");

      let inviter = Utils.owner;
      if (Utils.web3.utils.isAddress(affrAddr)) {
        inviter = affrAddr;
      }
      console.log(inviter);
      Utils.arbMiner.methods
        .buyEggs(callValue, inviter)
        .send({
          from: this.props.address,
          gasPrice: 200000000
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          message.info("Transaction sent", 3);
        })
        .once("receipt", (res) => {
          message.info("Transaction confirmed", 3);
        })
        .then((res) => {})
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  compound = async () => {
    if (!checkTime(ARB_MINER_RELEASE_TIME)) {
      message.info("Coming Soon!");
      return;
    }
    if (
      !this.props.address ||
      this.props.address === "0x00000000000000000000000000000000deadbeef"
    ) {
      message.info(
        "Please use browser with Arbitrum One wallet installed to open the site."
      );
      return;
    }
    if (this.props.nativeBalance * 1 < 0.0002) {
      message.warning("Insufficient Gas (<0.0002)");
      return;
    }
    if (this.props.myEarns * 1 < 0.1) {
      message.warning(
        "The minimum compound amount should not be less than 0.1 ARB"
      );
      return;
    }
    try {
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      let affrAddr = urlParams.get("ref");

      let inviter = Utils.owner;
      if (Utils.web3.utils.isAddress(affrAddr)) {
        inviter = affrAddr;
      }
      console.log(inviter);
      Utils.arbMiner.methods
        .hatchEggs(inviter)
        .send({
          from: this.props.address,
          gasPrice: 200000000
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          message.info("Transaction sent", 3);
        })
        .once("receipt", (res) => {
          message.info("Transaction confirmed", 3);
        })
        .then((res) => {})
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  withdraw = () => {
    if (!checkTime(ARB_MINER_RELEASE_TIME)) {
      message.info("Coming Soon!");
      return;
    }
    if (
      !this.props.address ||
      this.props.address === "0x00000000000000000000000000000000deadbeef"
    ) {
      message.info(
        "Please use browser with Arbitrum One wallet installed to open the site."
      );
      return;
    }

    if (this.props.nativeBalance * 1 < 0.0003) {
      message.warning("Insufficient Gas");
      return;
    }
    try {
      Utils.arbMiner.methods
        .sellEggs()
        .send({
          from: this.props.address,
          gasPrice: 200000000
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          message.info("Transaction sent", 3);
        })
        .once("receipt", (res) => {
          message.info("Transaction confirmed", 3);
        })
        .then((res) => {})
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };
}

export default ArbMiner;
